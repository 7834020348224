import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import ReactGA from "react-ga4";
import historyService from "./services/historyService";

import { icons } from './assets/icons'

React.icons = icons
ReactGA.initialize("G-HX9NP10CZ9", {
    debug: true,
    gaOptions: {
      allowLinker: true,
    },
  });
  ReactGA.ga('require', 'linker');
  ReactGA.ga('linker:autoLink', ['app.ares.thefitlab.io']);
  historyService.listen((location) => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  });

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

